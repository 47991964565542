import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

import arrow from '../../../images/svg/arrow.svg';
import ModalStudy from './More-modal';


const Study = () => {

  const [isShownGrad, setIsShownGrad] = useState(false);
  const [isShownPos, setIsShownPos] = useState(false);
  const [showPosModal, setShowPosModal] = useState(false);
  const [showGradModal, setShowGradModal] = useState(false);

  function openPosModal() {
    setShowPosModal(true);
  }

  function openGradModal() {
    setShowGradModal(true);
  }

  function hide() {
    setShowGradModal(false);
    setShowPosModal(false);
  }

  /* static query */
  const bgImages = useStaticQuery(
    graphql`
      query {
        allFile(filter: {relativePath: {in: ["grad.png", "pos.png"]}}) {
          edges {
            node {
              relativePath:
              childImageSharp {
                fluid {
                 src
                }
              }
            }
          }
        }
      }
    `
  )

  const images = bgImages.allFile.edges.map(({ node }) => node);

  const gradImg = images[0]?.relativePath.fluid.src;
  const posImg = images[1]?.relativePath.fluid.src;

  return (
    <div className='text-center py-10 relative overflow-hidden'>
      <div data-aos="zoom-out-down" className='text-6xl text-primary font-bold mb-3'>O que você deseja estudar?</div>

      <div className="grid grid-cols-2 gap-15 md:px-90 p-5">
        {/* graduation card */}
        <div data-aos="fade-right" className='col-span-2 md:col-span-1 h-90 max-w-sm relative overflow-hidden rounded-xl'
          onMouseOver={() => setIsShownGrad(true)}
          onMouseOut={() => setIsShownGrad(false)}>

          <img className='absolute w-full h-full -z-1 rounded-xl' src={gradImg} alt="Graduação" />
          <div className='absolute bottom-0 w-full bg-accent text-white text-3xl font-bold rounded-b-xl py-1'>Graduação</div>

          {/* hover */}
          <div className={`absolute h-full w-full rounded-xl bg-primary bg-opacity-90 text-left p-3
              transition duration-500 ease-in-out transform ${!isShownGrad ? '-translate-y-full' : ''}`}>
            <div className="text-accent font-bold text-2xl mb-3">GRADUAÇÃO</div>
            <div className="text-white text-lg mb-3">
              Administração <br />
              Análise e Desenvolvimento de Sistemas <br />
              Design <br />
              Educação Física <br />
              Engenharia Civil <br />
              Processos Gerenciais <br />
              Marketing <br />
              Pedagogia <br />
            </div>
            <div className="text-white text-lg mb-3">E muito +</div>

            {/* open modal button */}
            <button onClick={openGradModal} className='absolute flex justify-center items-center bottom-3 right-3 w-12 h-12 cursor-pointer bg-white rounded-full shadow-md'>
              <span className='text-7xl font-bold text-accent -mt-1'>+</span>
            </button>

          </div>
        </div>

        {/* pos graduation card */}
        <div data-aos="fade-left" className='col-span-2 md:col-span-1 h-90 max-w-sm relative overflow-hidden rounded-xl'
          onMouseOver={() => setIsShownPos(true)}
          onMouseOut={() => setIsShownPos(false)}>

          <img className='absolute w-full h-full -z-1 rounded-xl' src={posImg} alt="Graduação" />
          <div className='absolute bottom-0 w-full bg-accent text-white text-3xl font-bold rounded-b-xl py-1'>Pós-Graduação</div>

          {/* hover */}
          <div className={`absolute h-full w-full rounded-xl bg-primary bg-opacity-90 text-left p-3
              transition duration-500 ease-in-out transform ${!isShownPos ? '-translate-y-full' : ''}`}>
            <div className="text-accent font-bold text-2xl mb-3">PÓS-GRADUAÇÃO</div>
            <div className="text-white text-lg mb-3">
              Administração <br />
              Áreas da Gestão <br />
              Áreas do Direito <br />
              Áreas da Engenharia <br />
              Áreas de Design e Comunicação <br />
              Áreas da Tecnologia <br />
            </div>
            <div className="text-white text-lg mb-3">E muito +</div>

            {/* open modal button */}
            <button onClick={openPosModal} className='absolute flex justify-center items-center bottom-3 right-3 w-12 h-12 cursor-pointer bg-white rounded-full shadow-md'>
              <span className='text-7xl font-bold text-accent -mt-1'>+</span>
            </button>

          </div>
        </div>
      </div>

      {/* primary button */}
      <div className='flex justify-center items-center mt-10'>
        <Link to="/register" className='bg-primary text-white font-bold py-2 px-4 rounded-xl shadow-md hover:bg-accent text-2xl border-white border-2 transition-all'>
          GARANTA SUA VAGA AGORA!
        </Link>
      </div>

      {/* arrow */}
      <img className='hidden md:block absolute left-0 top-0 h-full' src={arrow} alt="arrow" />

      {/* modal study */}
      <ModalStudy showPosModal={showPosModal} showGradModal={showGradModal} hide={hide} />
    </div>
  )
}

export default Study