import React, { useEffect } from 'react';
import Banner from '../components/Home/Banner';
import Differential from '../components/Home/Differential';
import HowWorks from '../components/Home/HowWork';
import Ingress from '../components/Home/Ingress';
import Study from '../components/Home/Study';
import Layout from '../components/layout';

import Aos from 'aos';
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet';

const Index = () => {

  useEffect(() => {
    Aos.init({ duration: 1000, easing: 'ease-out', once: true });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Unisul Digital</title>
      </Helmet>
      <Banner></Banner>
      <Study></Study>
      <HowWorks></HowWorks>
      <Ingress></Ingress>
      <Differential></Differential>
    </Layout>
  )
}

export default Index