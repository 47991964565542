import React, { useEffect, useState } from 'react'
import mec from '../../../images/svg/mec.svg'
import bag from '../../../images/svg/bag.svg'
import dif1 from '../../../images/svg/dif-1.svg'
import dif2 from '../../../images/svg/dif-2.svg'
import certificado from '../../../images/svg/certificado.svg'
/* import ModalRegister from '../Modal' */
import { Link } from 'gatsby'

function Differential() {

  const [scrollY, setScrollY] = useState(0);
  const [width, setWidth] = useState(0);
  const thisRef = React.useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      setWidth(window.innerWidth);
      setScrollY((window.scrollY));
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };


  }, []);

  return (
    <div ref={thisRef} className='bg-primary py-10 text-center text-white overflow-hidden relative'>
      <div data-aos="fade-down" data-aos-delay="0" className='text-6xl text-white font-bold mb-3 z-99999'>Nossos diferenciais</div>

      <div className='grid grid-cols-3 z-99 md:px-20'>

        {/* mec */}
        <div data-aos="fade-up" data-aos-delay="100" className='col-span-3 md:col-span-1 py-10 md:py-0 flex flex-col text-center items-center gap-4 md:px-10 z-999'>
          <img className='w-24 h-24' src={mec} alt="mec" />
          <div>
            <div className='font-bold text-2xl'>CONCEITO MÁXIMO NO MEC</div>
            <div className=''>
              Estude com a qualidade de uma das melhores universidades privadas de Santa Catarina, que obteve Conceito 5 do MEC.
            </div>
          </div>
        </div>

        {/* projeto */}
        <div data-aos="fade-up" data-aos-delay="200" className='col-span-3 md:col-span-1 py-10 md:py-0 flex flex-col text-center items-center gap-4 md:border-l-2 md:border-r-2 border-white md:px-10 z-999'>
          <img className='w-24 h-24' src={bag} alt="mec" />
          <div>
            <div className='font-bold text-2xl'>PROJETO DE VIDA E CARREIRA</div>
            <div className=''>
              Você se prepara com a gente para o mercado de trabalho.
            </div>
          </div>
        </div>

        {/* professores */}
        <div data-aos="fade-up" data-aos-delay="300" className='col-span-3 md:col-span-1 py-10 md:py-0 flex flex-col text-center items-center gap-4 md:px-10 z-999'>
          <img className='w-24 h-24' src={certificado} alt="mec" />
          <div>
            <div className='font-bold text-2xl'>PROFESSORES QUALIFICADOS</div>
            <div className=''>
              Temos um grupo constituído por especialistas em educação a distância e na área de conhecimento da disciplina/curso.
            </div>
          </div>
        </div>

      </div>

      {/* primary button */}
      <div className='flex justify-center items-center mt-10'>
        {/*  <ModalRegister label={'INSCREVA-SE JÁ!'} classNames='bg-accent text-white font-bold py-2 px-4 rounded-md shadow-md text-2xl hover:bg-primary-900'></ModalRegister> */}
        <Link to='/register' className='bottom-3 mx-auto mt-5 bg-accent text-white py-2 px-10 rounded-xl text-xl font-bold overflow-hidden hover:bg-primary-900 border-2 border-white transition-all'>INSCREVA-SE JÁ!</Link>
      </div>

      <img style={{ top: (scrollY - (scrollY + 300)) + scrollY * 0.11 }}
        className='hidden md:block absolute z-0 left-0 top-0' src={dif1} alt="diferencial" />
      <img style={{ bottom: (scrollY - (scrollY + 500)) + scrollY * 0.2 }}
        className='hidden md:block absolute z-0 right-0 bottom-0' src={dif2} alt="diferencial" />

      {/* <div className='fixed left-0 bottom-0 bg-primary'>
        <div>{scrollY}</div>
        <div>{(scrollY - (scrollY + 200))}</div>
        <div>{scrollY * 0.05}</div>
      </div> */}

    </div>


  )
}

export default Differential