import axios from "axios";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { Link, navigate } from "gatsby";

function Form({ close, setShowThanks, register }) {
  const api = "https://pf-api.prouniversidade.com.br";
  //const api = 'http://localhost/pro-floripa-new/pf-api';

  const [state, setState] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    course: "",
    pole: "",
  });

  const handleInputChange = (event) => {
    state[event.target.name] = event.target.value;
    setState({ ...state });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    if (close) {
      close();
    }

    if (setShowThanks) {
      setShowThanks(true);
    }

    axios
      .post(`${api}/contact-unisul`, {
        ...state,
        campaign: "unisul-digital-saber-mais",
      })
      .then((res) => {
        console.log("Formulário enviado com sucesso!", res);
      })
      .catch((err) => {
        console.log("Erro ao enviar formulário", err);
      });

    if (register) {
      navigate(`/register`, { state: { ...state } });
    }
  };

  return (
    <div>
      <div className="max-w-full md:max-w-md xl:max-w-xl md:ml-auto mx-5 max-h-full bg-accent shadow-md py-10 px-10 relative rounded-xl">
        <div className="text-primary font-bold text-5xl text-center">
          QUER SABER MAIS?
        </div>
        <div className="text-md text-white text-center mb-5">
          Deixe seu contato que vamos tirar todas suas dúvidas!
        </div>

        <form className="form" onSubmit={handleOnSubmit}>
          <div className="md:grid md:grid-cols-2 md:gap-5">
            {/* name */}
            <div className="flex flex-col pb-4 col-span-1">
              <input
                id="name"
                name="name"
                value={state.name}
                onChange={handleInputChange}
                placeholder="Nome"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>

            {/* surname */}
            <div className="flex flex-col pb-4 col-span-1">
              <input
                id="surname"
                name="surname"
                value={state.surname}
                onChange={handleInputChange}
                placeholder="Sobrenome"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>
          </div>

          {/* phone */}
          <div className="flex flex-col pb-4">
            <InputMask
              mask="(99) 99999-9999"
              maskChar={null}
              value={state.phone}
              onChange={handleInputChange}
              id="phone"
              name="phone"
              placeholder="Celular"
              className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
              type="text"
            />
          </div>

          {/* email */}
          <div className="flex flex-col pb-4">
            <input
              id="email"
              name="email"
              value={state.email}
              onChange={handleInputChange}
              placeholder="E-mail"
              className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
              type="email"
              required
            />
          </div>

          {/* course */}
          <div className="flex flex-col pb-4">
            <input
              id="course"
              name="course"
              value={state.course}
              onChange={handleInputChange}
              placeholder="Curso pretendido"
              className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
              type="text"
              required
            />
          </div>

          {/* pole */}
          <div className="flex flex-col pb-4">
            <div className="text-white text-xl font-bold mb-3">Polo</div>
            <div className="flex items-center justify-around">
              {/* Floripa */}
              <div className="flex gap-2 itens-center">
                <input
                  id="pole"
                  name="pole"
                  value="Florianópolis"
                  onChange={handleInputChange}
                  type="radio"
                  required
                />
                <label className="text-primary text-xl font-bold">
                  Floripa
                </label>
              </div>

              {/* Palhoça */}
              <div className="flex gap-2 itens-center">
                <input
                  id="pole"
                  name="pole"
                  value="Palhoça"
                  onChange={handleInputChange}
                  type="radio"
                  required
                />
                <label className="text-primary text-xl font-bold">
                  Palhoça
                </label>
              </div>

              {/* Biguaçu */}
              <div className="flex gap-2 itens-center">
                <input
                  id="pole"
                  name="pole"
                  value="Biguaçu"
                  onChange={handleInputChange}
                  type="radio"
                  required
                />
                <label className="text-primary text-xl font-bold">
                  Biguaçu
                </label>
              </div>
            </div>
          </div>

          <div>
            Nós usaremos seus dados para entrar em contato com você sobre as
            informações correlacionais que podem ser de seu interesse. Você pode
            cancelar o envio da divulgação, a qualquer momento, utilizando o
            opt-out existentes nas mensagens encaminhadas por nós. Para maiores
            informações, acesse nosso{" "}
            <Link to="/canal-de-privacidade" className="text-primary-500">
              canal de privacidade
            </Link>
            .
          </div>

          <button
            type="submit"
            className="w-full bottom-3 mx-auto mt-5 bg-primary text-white py-2 px-10 rounded-xl text-xl font-bold overflow-hidden hover:bg-primary-900 border-2 border-white transition-all"
          >
            MATRICULE-SE JÁ!
          </button>
        </form>
      </div>
    </div>
  );
}

export default Form;
