export const pos = [
  "ESPECIALIZAÇÃO EM ALFABETIZAÇÃO E LETRAMENTO",
  "ESPECIALIZAÇÃO EM APRENDIZAGEM E INOVAÇÃO",
  "ESPECIALIZAÇÃO EM ARQUITETURA COMERCIAL E VISUAL MERCHANDISING",
  "ESPECIALIZAÇÃO EM ARQUITETURA HOSPITALAR",
  "ESPECIALIZAÇÃO EM ASSESSORIA E GESTÃO DA COMUNICAÇÃO",
  "ESPECIALIZAÇÃO EM BIG DATA",
  "ESPECIALIZAÇÃO EM BIG DATA E COMUNICAÇÃO",
  "ESPECIALIZAÇÃO EM BIG DATA E MARKETING",
  "ESPECIALIZAÇÃO EM BUSINESS INTELLIGENCE E ANALYTICS",
  "ESPECIALIZAÇÃO EM COMUNICAÇÃO E MARKETING",
  "ESPECIALIZAÇÃO EM COMUNICAÇÃO E PRODUÇÃO DE MODA",
  "ESPECIALIZAÇÃO EM COMUNICAÇÃO EM CRISES ORGANIZACIONAIS",
  "ESPECIALIZAÇÃO EM COMUNICAÇÃO INSTITUCIONAL",
  "ESPECIALIZAÇÃO EM COMUNICAÇÃO NAS ORGANIZAÇÕES",
  "ESPECIALIZAÇÃO EM COMUNICAÇÃO, MARCAS E CONSUMO",
  "ESPECIALIZAÇÃO EM CONFEITARIA E PANIFICAÇÃO",
  "ESPECIALIZAÇÃO EM COOLHUNTING - PESQUISA DE TENDÊNCIAS E INOVAÇÕES",
  "ESPECIALIZAÇÃO EM COORDENAÇÃO PEDAGÓGICA",
  "ESPECIALIZAÇÃO EM COSMETOLOGIA ESTÉTICA",
  "ESPECIALIZAÇÃO EM COZINHA BRASILEIRA",
  "ESPECIALIZAÇÃO EM CUSTOMER EXPERIENCE EM TURISMO",
  "ESPECIALIZAÇÃO EM DATA SCIENCE",
  "ESPECIALIZAÇÃO EM DESENVOLVIMENTO DE GAMES",
  "ESPECIALIZAÇÃO EM DESENVOLVIMENTO DE SOFTWARE COM METODOLOGIA ÁGIL",
  "ESPECIALIZAÇÃO EM DESENVOLVIMENTO E SUSTENTABILIDADE",
  "ESPECIALIZAÇÃO EM DESIGN DE INTERIORES",
  "ESPECIALIZAÇÃO EM DIREITO ADMINISTRATIVO",
  "ESPECIALIZAÇÃO EM DIREITO ADMINISTRATIVO E DIREITO TRIBUTÁRIO",
  "ESPECIALIZAÇÃO EM DIREITO AMBIENTAL",
  "ESPECIALIZAÇÃO EM DIREITO CIVIL",
  "ESPECIALIZAÇÃO EM DIREITO CIVIL E DIREITO DE DEFESA DO CONSUMIDOR",
  "ESPECIALIZAÇÃO EM DIREITO CIVIL E DIREITO DO TRABALHO",
  "ESPECIALIZAÇÃO EM DIREITO CIVIL E DIREITO EMPRESARIAL",
  "ESPECIALIZAÇÃO EM DIREITO CIVIL E PROCESSO CIVIL",
  "ESPECIALIZAÇÃO EM DIREITO DA COMUNICAÇÃO DIGITAL E AS TELECOMUNICAÇÕES",
  "ESPECIALIZAÇÃO EM DIREITO DAS FAMÍLIAS E DE SUCESSÃO",
  "ESPECIALIZAÇÃO EM DIREITO DIGITAL",
  "ESPECIALIZAÇÃO EM DIREITO DO TRABALHO E DIREITO PREVIDENCIÁRIO",
  "ESPECIALIZAÇÃO EM DIREITO E PROCESSO DO TRABALHO",
  "ESPECIALIZAÇÃO EM DIREITO E PROCESSO PENAL",
  "ESPECIALIZAÇÃO EM DIREITO EMPRESARIAL",
  "ESPECIALIZAÇÃO EM DIREITO EMPRESARIAL E PROCESSO CIVIL",
  "ESPECIALIZAÇÃO EM DIREITO IMOBILIÁRIO",
  "ESPECIALIZAÇÃO EM DIREITO INTERNACIONAL",
  "ESPECIALIZAÇÃO EM DIREITO PREVIDENCIÁRIO",
  "ESPECIALIZAÇÃO EM DIREITO PÚBLICO",
  "ESPECIALIZAÇÃO EM DIREITO REAL DE FAMÍLIA E DE SUCESSÕES E PROCESSO CIVIL",
  "ESPECIALIZAÇÃO EM DIREITO TRABALHISTA",
  "ESPECIALIZAÇÃO EM DIREITO TRIBUTÁRIO",
  "ESPECIALIZAÇÃO EM DIREITOS HUMANOS, GÊNERO E SEXUALIDADE",
  "ESPECIALIZAÇÃO EM DOCÊNCIA DA LÍNGUA INGLESA",
  "ESPECIALIZAÇÃO EM DOCÊNCIA EM ENFERMAGEM",
  "ESPECIALIZAÇÃO EM DOCÊNCIA PARA O ENSINO SUPERIOR",
  "ESPECIALIZAÇÃO EM EDUCAÇÃO A DISTÂNCIA E INOVAÇÃO",
  "ESPECIALIZAÇÃO EM EDUCAÇÃO BILÍNGUE",
  "ESPECIALIZAÇÃO EM EDUCAÇÃO INCLUSIVA COM ÊNFASE EM TECNOLOGIAS ASSISTIVAS",
  "ESPECIALIZAÇÃO EM EDUCAÇÃO INFANTIL",
  "ESPECIALIZAÇÃO EM EDUCAÇÃO NA ATUALIDADE",
  "ESPECIALIZAÇÃO EM EDUCOMUNICAÇÃO",
  "ESPECIALIZAÇÃO EM EMPREENDEDORISMO E DESENVOLVIMENTO DE NOVOS NEGÓCIOS",
  "ESPECIALIZAÇÃO EM ENERGIAS RENOVÁVEIS",
  "ESPECIALIZAÇÃO EM ENFERMAGEM DO TRABALHO",
  "ESPECIALIZAÇÃO EM GAMIFICAÇÃO",
  "ESPECIALIZAÇÃO EM GAMIFICAÇÃO EM CONTEXTOS EDUCACIONAIS",
  "ESPECIALIZAÇÃO EM GASTRONOMIA",
  "ESPECIALIZAÇÃO EM GASTRONOMIA FUNCIONAL",
  "ESPECIALIZAÇÃO EM GESTÃO DA COMUNICAÇÃO EM REDES SOCIAIS",
  "ESPECIALIZAÇÃO EM GESTÃO DA MANUTENÇÃO",
  "ESPECIALIZAÇÃO EM GESTÃO DE BUSINESS INTELLIGENCE",
  "ESPECIALIZAÇÃO EM GESTÃO DE E-COMMERCE",
  "ESPECIALIZAÇÃO EM GESTÃO DE NEGÓCIOS EM GASTRONOMIA",
  "ESPECIALIZAÇÃO EM GESTÃO DE OBRAS, QUALIDADE E DESEMPENHO DAS CONSTRUÇÕES",
  "ESPECIALIZAÇÃO EM GESTÃO DE POLÍTICAS AMBIENTAIS",
  "ESPECIALIZAÇÃO EM GESTÃO DE PROJETOS DE ARQUITETURA",
  "ESPECIALIZAÇÃO EM GESTÃO DE REDES DE COMPUTADORES E COMUNICAÇÃO DE DADOS",
  "ESPECIALIZAÇÃO EM GESTÃO DO VAREJO",
  "ESPECIALIZAÇÃO EM GESTÃO E ECONOMIA DE ENERGIA",
  "ESPECIALIZAÇÃO EM GESTÃO EM ENFERMAGEM",
  "ESPECIALIZAÇÃO EM GESTÃO ESTRATÉGICA DO DESIGN",
  "ESPECIALIZAÇÃO EM GESTÃO HOSPITALAR",
  "ESPECIALIZAÇÃO EM GOVERNANÇA DE TECNOLOGIA DA INFORMAÇÃO E INOVAÇÃO",
  "ESPECIALIZAÇÃO EM GRAMÁTICA E TEXTO",
  "ESPECIALIZAÇÃO EM INFLUÊNCIA DIGITAL",
  "ESPECIALIZAÇÃO EM JOGOS DIGITAIS",
  "ESPECIALIZAÇÃO EM JORNALISMO INVESTIGATIVO",
  "ESPECIALIZAÇÃO EM LIBRAS - LÍNGUA BRASILEIRA DE SINAIS",
  "ESPECIALIZAÇÃO EM LICENCIAMENTO E AUDITORIA AMBIENTAL",
  "ESPECIALIZAÇÃO EM METODOLOGIA DO ENSINO SUPERIOR",
  "ESPECIALIZAÇÃO EM MÍDIAS SOCIAIS NAS ORGANIZAÇÕES",
  "ESPECIALIZAÇÃO EM NEGOCIAÇÃO E VENDAS",
  "ESPECIALIZAÇÃO EM NEGÓCIOS EM MÍDIAS DIGITAIS",
  "ESPECIALIZAÇÃO EM NEUROCIÊNCIA APLICADA À EDUCAÇÃO",
  "ESPECIALIZAÇÃO EM PRÁTICAS DE CONSUMO E MÍDIAS SOCIAIS",
  "ESPECIALIZAÇÃO EM PROCESSO CIVIL",
  "ESPECIALIZAÇÃO EM PROCESSO CIVIL E PROCESSO DO TRABALHO",
  "ESPECIALIZAÇÃO EM PRODUÇÃO AUDIOVISUAL MULTIPLATAFORMA",
  "ESPECIALIZAÇÃO EM PROJETOS ÁGEIS PARA APLICATIVOS MÓVEIS",
  "ESPECIALIZAÇÃO EM PSICOLOGIA ORGANIZACIONAL",
  "ESPECIALIZAÇÃO EM PSICOPEDAGOGIA",
  "ESPECIALIZAÇÃO EM PSICOPEDAGOGIA EDUCACIONAL",
  "ESPECIALIZAÇÃO EM PSICOPEDAGOGIA INSTITUCIONAL",
  "ESPECIALIZAÇÃO EM RECURSOS HUMANOS",
  "ESPECIALIZAÇÃO EM RELAÇÕES INTERNACIONAIS",
  "ESPECIALIZAÇÃO EM RESOLUÇÃO DE CONFLITOS E DIREITO DO TRABALHO",
  "ESPECIALIZAÇÃO EM RESOLUÇÃO DE CONFLITOS E PROCESSO CIVIL",
  "ESPECIALIZAÇÃO EM SAÚDE PÚBLICA COM ÊNFASE EM SAÚDE DA FAMÍLIA",
  "ESPECIALIZAÇÃO EM SEGURANÇA ALIMENTAR",
  "ESPECIALIZAÇÃO EM SEGURANÇA DA INFORMAÇÃO E GESTÃO DE TI",
  "ESPECIALIZAÇÃO EM SERVIÇO SOCIAL E GESTÃO DE PROJETOS SOCIAIS",
  "ESPECIALIZAÇÃO EM TECNOLOGIAS EDUCACIONAIS",
  "ESPECIALIZAÇÃO EM TEXTO NA ATUALIDADE",
  "ESPECIALIZAÇÃO EM USER EXPERIENCE",
  "MBA EM BUSINESS ANALYTICS NAS ORGANIZAÇÕES",
  "MBA EM COMÉRCIO EXTERIOR",
  "MBA EM CONSULTORIA EMPRESARIAL",
  "MBA EM CONTROLADORIA, AUDITORIA E COMPLIANCE",
  "MBA EM DEFESA CIBERNÉTICA NAS ORGANIZAÇÕES",
  "MBA EM EDUCAÇÃO CORPORATIVA",
  "MBA EM FINANÇAS CORPORATIVAS",
  "MBA EM FINANÇAS E BANKING",
  "MBA EM GASTRONOMIA E GESTÃO DE ALIMENTOS E BEBIDAS",
  "MBA EM GESTÃO DA QUALIDADE EM TECNOLOGIA DA INFORMAÇÃO",
  "MBA EM GESTÃO DA SAÚDE COM ÊNFASE EM ADMINISTRAÇÃO HOSPITALAR",
  "MBA EM GESTÃO DA TECNOLOGIA DA INFORMAÇÃO",
  "MBA EM GESTÃO DE CARREIRA E EMPREGABILIDADE",
  "MBA EM GESTÃO DE E-COMMERCE DE MODA",
  "MBA EM GESTÃO DE ENERGIA, PETRÓLEO E GÁS",
  "MBA EM GESTÃO DE EVENTOS",
  "MBA EM GESTÃO DE IMAGEM E BRANDING",
  "MBA EM GESTÃO DE MARCAS",
  "MBA EM GESTÃO DE NEGÓCIOS DE EDUCAÇÃO",
  "MBA EM GESTÃO DE NEGÓCIOS DE EDUCAÇÃO A DISTÂNCIA",
  "MBA EM GESTÃO DE NEGÓCIOS EM TURISMO",
  "MBA EM GESTÃO DE PROCESSOS",
  "MBA EM GESTÃO DE PROJETOS",
  "MBA EM GESTÃO DE PROJETOS ÁGEIS",
  "MBA EM GESTÃO DE PROJETOS SOCIAIS E CULTURAIS",
  "MBA EM GESTÃO DE SERVIÇOS",
  "MBA EM GESTÃO DE SERVIÇOS EDUCACIONAIS",
  "MBA EM GESTÃO DE STARTUPS",
  "MBA EM GESTÃO DE TURISMO E HOTELARIA",
  "MBA EM GESTÃO DE VAREJO E E-COMMERCE",
  "MBA EM GESTÃO EDUCACIONAL",
  "MBA EM GESTÃO EMPRESARIAL",
  "MBA EM GESTÃO ESCOLAR",
  "MBA EM GESTÃO ESCOLAR COM ÊNFASE EM EDUCAÇÃO INCLUSIVA",
  "MBA EM GESTÃO ESTRATÉGICA DE NEGÓCIOS",
  "MBA EM GESTÃO ESTRATÉGICA DE PESSOAS",
  "MBA EM GESTÃO ESTRATÉGICA DE VENDAS",
  "MBA EM GESTÃO PÚBLICA",
  "MBA EM GESTÃO PÚBLICA COM ÊNFASE EM MEIO AMBIENTE E ENERGIA",
  "MBA EM GESTÃO PÚBLICA COM ÊNFASE EM SAÚDE",
  "MBA EM GESTÃO PÚBLICA E DIREITOS HUMANOS",
  "MBA EM INTELIGÊNCIA COMPETITIVA",
  "MBA EM LIDERANÇA ESTRATÉGICA",
  "MBA EM LOGÍSTICA E SUPPLY CHAIN MANAGEMENT",
  "MBA EM LOGÍSTICA EMPRESARIAL",
  "MBA EM MARKETING DIGITAL",
  "MBA EM MARKETING E VENDAS",
  "MBA EM MARKETING ESTRATÉGICO",
  "MBA EM NEGÓCIOS DE MODA",
  "MBA EM NEGÓCIOS DIGITAIS",
  "MBA EM NEGÓCIOS IMOBILIÁRIOS",
  "MBA EM NEGÓCIOS INTERNACIONAIS",
  "MBA EM PLANEJAMENTO TRIBUTÁRIO",
  "MBA EM SERVIÇOS DE SAÚDE",
  "MBA EM TENDÊNCIAS E INOVAÇÕES",
]