import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

import bannerLine from "../../../images/svg/banner-line.svg";
import Form from "../Form";

const BannerContainer = styled.div`
  min-height: calc(100vh - 56px);
  background-color: #293189;
`;

const Banner = () => {
  const img = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "people2.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    `
  );

  const peopleImg = img?.img.childImageSharp.fluid.src;

  return (
    <BannerContainer className="w-full md:px-15 px-5 py-5 relative bg-cover bg-center bg-no-repeat z-90 text-white flex flex-col justify-center items-center md:items-start md:grid md:grid-cols-2 gap-15 overflow-hidden">
      <div className="col-span-1 md:pr-40 md:pt-20 text-center md:text-left">
        <div
          className="text-8xl font-bold mb-3 leading-none"
          data-aos="fade-right"
          data-aos-delay="500"
        >
          Bolsas de estudo de até
        </div>
        <div
          className="text-10xl font-extrabold mb-3 text-accent-500"
          data-aos="fade-right"
          data-aos-delay="530"
        >
          100%
        </div>
        <div
          className="text-5xl md:pr-40 leading-none"
          data-aos="fade-right"
          data-aos-delay="550"
        >
          Escolha a universidade mais inovadora de SC!
        </div>
      </div>
      <div data-aos="fade-left" className="col-span-1 hidden md:block">
        <Form register={true}></Form>
      </div>

      {/* lines */}
      <img
        className="hidden md:block absolute h-full left-0 right-0 -z-1"
        src={bannerLine}
        alt="Banner lines"
      />

      {/* people */}
      <img
        data-aos="fade-up"
        data-aos-delay="700"
        className="hidden md:block absolute h-3/4 left-72 right-0 bottom-0 -z-1"
        src={peopleImg}
        alt="Banner people"
      />
    </BannerContainer>
  );
};

export default Banner;
