export const grad = [
  "ADMINISTRAÇÃO",
  "AGRONEGÓCIO",
  "ANÁLISE E DESENVOLVIMENTO DE SISTEMAS",
  "ARTES VISUAIS",
  "BIG DATA E INTELIGÊNCIA ANALÍTICA",
  "BLOCKCHAIN E CRIPTOGRAFIA DIGITAL",
  "CIÊNCIA DA COMPUTAÇÃO",
  "CIÊNCIAS BIOLÓGICAS",
  "CIÊNCIAS CONTÁBEIS",
  "CIÊNCIAS ECONÔMICAS",
  "CIÊNCIAS SOCIAIS",
  "COMÉRCIO EXTERIOR",
  "DESIGN DE ANIMAÇÃO",
  "DESIGN DE GAMES",
  "DESIGN DE INTERIORES",
  "DESIGN DE PRODUTO",
  "DESIGN GRÁFICO",
  "BACHARELADO EM EDUCAÇÃO FÍSICA",
  "LICENCIATURA EM EDUCAÇÃO FÍSICA",
  "ENGENHARIA CIVIL",
  "ENGENHARIA DE COMPUTAÇÃO",
  "ENGENHARIA DE CONTROLE E AUTOMAÇÃO",
  "ENGENHARIA DE PRODUÇÃO",
  "ENGENHARIA ELÉTRICA",
  "ENGENHARIA MECÂNICA",
  "ESTATÍSTICA",
  "EVENTOS",
  "GASTRONOMIA",
  "GEOGRAFIA",
  "GESTÃO AMBIENTAL",
  "GESTÃO COMERCIAL",
  "GESTÃO DA INOVAÇÃO E EMPREENDEDORISMO DIGITAL",
  "GESTÃO DA PRODUÇÃO INDUSTRIAL",
  "GESTÃO DA QUALIDADE",
  "GESTÃO DA TECNOLOGIA DA INFORMAÇÃO",
  "GESTÃO DE NEGÓCIOS DIGITAIS",
  "GESTÃO DE RECURSOS HUMANOS",
  "GESTÃO FINANCEIRA",
  "GESTÃO HOSPITALAR",
  "GESTÃO PÚBLICA",
  "HISTÓRIA",
  "HOTELARIA",
  "INTERNET DAS COISAS E COMPUTAÇÃO EM NUVEM",
  "JOGOS DIGITAIS",
  "LETRAS - INGLÊS",
  "LETRAS - LÍNGUA PORTUGUESA",
  "LOGÍSTICA",
  "MARKETING",
  "MARKETING DIGITAL",
  "BACHARELADO EM MATEMÁTICA",
  "LICENCIATURA EM MATEMÁTICA",
  "MEDIAÇÃO",
  "NEGÓCIOS IMOBILIÁRIOS",
  "PEDAGOGIA",
  "PROCESSOS GERENCIAIS",
  "PRODUÇÃO MULTIMÍDIA EM REALIDADE AUMENTADA",
  "REDES DE COMPUTADORES",
  "SECRETARIADO EXECUTIVO",
  "PEDAGOGIA - SEGUNDA LICENCIATURA",
  "SEGURANÇA DA INFORMAÇÃO",
  "SEGURANÇA PRIVADA",
  "SEGURANÇA PÚBLICA",
  "SERVIÇO SOCIAL",
  "SERVIÇOS JUDICIAIS",
  "SERVIÇOS NOTARIAIS E REGISTRAIS",
  "SERVIÇOS PENAIS",
  "SISTEMAS DE INFORMAÇÃO",
  "SISTEMAS PARA INTERNET",
  "TEOLOGIA",
  "GESTÃO DO TURISMO",
  "CIÊNCIAS AERONÁUTICAS",
  "RELAÇÕES INTERNACIONAIS",
  "COMUNICAÇÃO INSTITUCIONAL",
  "FILOSOFIA",
  "SEGURANÇA NO TRÂNSITO",
  "HISTÓRIA - BACHAREL",
]