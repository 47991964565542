import React, { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { pos } from '../../../../data/pos';
import { grad } from '../../../../data/grad';


const ModalStudy = ({ showPosModal, showGradModal, hide }) => {

  const [Modal, open, close] = useModal('modal', {
    preventScroll: false,
    closeOnOverlayClick: false
  });

  useEffect(() => {
    if (showPosModal || showGradModal) {
      open();
    }
  }, [showPosModal, showGradModal]);

  function closeModal() {
    close();
    hide();
  }

  return (
    <div id="modal">
      <Modal>
        <div className='bg-primary max-h-full p-10 rounded-md text-left'>
          <div className="text-accent font-bold text-2xl mb-3">
            {showPosModal ? 'POS GRADUAÇÃO' : 'GRADUAÇÃO'}
          </div>
          <div className="text-sm text-accent my-2">Clique no curso desejado para mais informações</div>
          <div className="text-white text-lg mb-3 h-90 overflow-y-scroll pr-5">
            {showGradModal &&
              grad.map((g, i) => (
                <a key={g} href={`https://api.whatsapp.com/send?phone=5548991491166&text=Ol%C3%A1.%20Gostaria%20de%20saber%20mais%20sobre%20o%20curso%20${encodeURI(g)}`} target="_blank">
                  <div className={`rounded-md p-2 hover:bg-accent transition-all ${i % 2 ? 'bg-accent-50 bg-opacity-20' : ''}`}>{i + 1} - {g}</div>
                </a>
              ))
            }
            {showPosModal &&
              pos.map((p, i) => (
                <a key={p} href={`https://api.whatsapp.com/send?phone=5548991491166&text=Ol%C3%A1.%20Gostaria%20de%20saber%20mais%20sobre%20o%20curso%20${encodeURI(p)}`} target="_blank">
                  <div className={`rounded-md p-2 hover:bg-accent  transition-all ${i % 2 ? 'bg-accent-50 bg-opacity-20' : ''}`}>{i + 1} - {p}</div>
                </a>
              ))
            }
          </div>
        </div>
        <button className="rounded-full w-8 h-8 bg-accent text-white px-2 font-semibold text-2xl absolute -top-2 -right-2" onClick={closeModal}>x</button>
      </Modal>
    </div>
  )
}

export default ModalStudy
