import React, { useEffect } from 'react';
import { useModal } from 'react-hooks-use-modal';

const ModalThanks = ({ show, hide }) => {

  const [Modal, open, close] = useModal('modal', {
    preventScroll: true,
    closeOnOverlayClick: false
  });

  useEffect(() => {
    if (show) {
      open();
    }
  }, [show]);

  function closeModal() {
    if (hide) hide();
    close();
  }

  return (
    <div id="modal">
      <Modal>
        <div>
          <div className="max-w-full md:max-w-md md:ml-auto mx-5 max-h-full bg-accent shadow-md py-10 px-10 relative rounded-xl">
            <div className='text-primary font-bold text-4xl text-center'>AGRADECEMOS SEU CONTATO!</div>
            <div className="text-xl text-white text-center mb-5">Nossa equipe irá entrar em contato para tirar suas dúvidas.</div>

            {/* finalize btn */}
            <button
              onClick={closeModal}
              className='w-full bottom-3 mx-auto mt-5 bg-primary text-white py-2 px-10 rounded-xl text-xl font-bold overflow-hidden hover:bg-primary-900 border-2 border-white'>
              FINALIZAR!
            </button>
          </div>
        </div>



        {/* close float btn */}
        <button className="rounded-full w-8 h-8 bg-primary text-accent px-2 font-semibold text-2xl absolute -top-2 right-2" onClick={closeModal}>x</button>
      </Modal>
    </div>
  )
}

export default ModalThanks
