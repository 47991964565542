import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import arrow from '../../../images/svg/arrow2.svg';
import ModalRegister from '../Modal';


const HowWorksComponent = () => {

  const [scrollY, setScrollY] = useState(0);
  const [width, setWidth] = useState(0);
  const thisRef = React.useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      setWidth(window.innerWidth);
      if (width > 768) {
        setScrollY((window.scrollY / 10) + 'px');
      } else {
        setScrollY(-(window.scrollY / 10) + 'px');
      }
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };


  }, []);

  const img = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "how-work.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `);

  const bg = img.img.childImageSharp.fluid;

  return (
    <BackgroundImage
      Tag="section"
      fluid={bg}
      style={{ backgroundPositionX: scrollY }}
      className="transition-all duration-200 md:bg-contain"
    >
      <div ref={thisRef} className='h-full bg-primary-900  bg-opacity-80'>
        <div className='text-center md:px-90 py-10'>
          <div data-aos="fade-right" data-aos-delay="0" className='text-4xl text-white font-bold mb-5'>Você sabe como funciona o Ensino Digital da Unisul?</div>
          <div className='text-white my-15'>
            <div data-aos="fade-right" data-aos-delay="50" className='flex flex-nowrap items-center text-center text-3xl mb-2 justify-center hvr-grow'>
              <img className='w-7 h-7' src={arrow} alt="arrow" />
              <div>Sistema Tutorial</div>
            </div>
            <div data-aos="fade-right" data-aos-delay="100" className='flex flex-nowrap items-center text-center text-3xl mb-2 justify-center hvr-grow'>
              <img className='w-7 h-7' src={arrow} alt="arrow" />
              <div>Sistema de Avaliação</div>
            </div>
            <div data-aos="fade-right" data-aos-delay="150" className='flex flex-nowrap items-center text-center text-3xl mb-2 justify-center hvr-grow'>
              <img className='w-7 h-7' src={arrow} alt="arrow" />
              <div>Sistema de Comunicação</div>
            </div>
          </div>

          {/* primary button */}
          <div data-aos="fade-right" data-aos-delay="200" className='flex justify-center items-center mt-10'>
            <ModalRegister label={'QUERO SABER MAIS!'} classNames='bg-accent text-white font-bold py-2 px-10 rounded-xl shadow-md hover:bg-primary-900 text-2xl border-2 border-white transition-all'></ModalRegister>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

const HowWorks = styled(HowWorksComponent)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  `;

export default HowWorks