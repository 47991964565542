import React, { useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import Form from '../Form';
import ModalThanks from '../Thanks-modal';


const ModalRegister = ({ label, disabled, classNames }) => {

  const [showThanks, setShowThanks] = useState(false);

  const [Modal, open, close] = useModal('modal', {
    preventScroll: true,
    closeOnOverlayClick: false
  });

  return (
    <>
      <div id="modal">
        <Modal>
          <Form close={close} setShowThanks={setShowThanks}></Form>
          <button className="rounded-full w-8 h-8 bg-primary text-accent px-2 font-semibold text-2xl absolute -top-2 right-2" onClick={close}>x</button>
        </Modal>
        <button
          disabled={disabled}
          onClick={open}
          className={classNames}>
          {label}
        </button>
      </div>
      <ModalThanks show={showThanks} hide={() => { setShowThanks(false) }}  />
    </>
  )
}

export default ModalRegister
