import React, { useEffect } from 'react'
import arrow from '../../../images/svg/arrow2.svg';


function Ingress() {

  return (
    <div className='text-center px-10 py-10'>
      <div data-aos="fade-down" className='text-6xl text-primary font-bold mb-5'>Opções de ingresso</div>

      <div className='flex flex-col md:flex-row items-center justify-center mx-auto gap-5'>

        {/* enem */}
        <div data-aos="fade-right" data-aos-delay="400" className="flex flex-col w-50 h-50 justify-center items-center rounded-xl shadow-xl p-2 gap-2 hvr-grow">
          <img src={arrow} alt="arrow" />
          <span className="font-bold text-primary">Nota do ENEM</span>
        </div>

        {/* Vestibular */}
        <div data-aos="fade-right" data-aos-delay="300" className="flex flex-col w-50 h-50 justify-center items-center rounded-xl shadow-xl p-2 gap-2 hvr-grow">
          <img src={arrow} alt="arrow" />
          <span className="font-bold text-primary">Vestibular Online</span>
        </div>

        {/* transparência */}
        <div data-aos="fade-right" data-aos-delay="200" className="flex flex-col w-50 h-50 justify-center items-center rounded-xl shadow-xl p-2 gap-2 hvr-grow">
          <img src={arrow} alt="arrow" />
          <span className="font-bold text-primary">Transferência</span>
        </div>

        {/* 2 graduação */}
        <div data-aos="fade-right" data-aos-delay="100" className="flex flex-col w-50 h-50 justify-center items-center rounded-xl shadow-xl p-2 gap-2 hvr-grow">
          <img src={arrow} alt="arrow" />
          <span className="font-bold text-primary">2ª Graduação</span>
        </div>

      </div>
    </div>
  )
}

export default Ingress